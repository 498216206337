import $ from "jquery";
import moment from 'moment';
import {basicChartInitialState} from '../components/programs/BasicChart/basic-chart-initial-state';

let commoditiesObject = {};

function getCommoditiesObject() {
    return commoditiesObject;
}

function setCommoditiesObject(object) {
    commoditiesObject = object;
}

function areSameUnits(spread) {
    let contracts = spread.replace(/\s/g, '').split('/');
    let units = contracts.map(x => commodityUnits(contractNameDecomposer(x).commoditySymbol));
    let sameUnits = units.every((val, i, arr) => val === arr[0]);
    return sameUnits;
}

function areSameUnitMoves(spread) {
    let contracts = spread.replace(/\s/g, '').split('/');
    let unitMove = contracts.map(x => getUnitMove(contractNameDecomposer(x).commoditySymbol));
    // console.log("unitMove=" + unitMove);
    let sameUnitMoves = unitMove.every((val, i, arr) => val === arr[0]);
    return sameUnitMoves;
}

function areSameUnitsAndUnitMoves(spread) {
    return  areSameUnitMoves(spread) && areSameUnits(spread);
}

function commodityUnits(commoditySymbol) {
    // console.log("commodityUnits() starting. commoditySymbol=" + commoditySymbol);
    return commoditiesObject[commoditySymbol].units;
}

function getUnitMove(commoditySymbol) {
    // console.log("getUnitMove() starting. commoditySymbol=" + commoditySymbol);
    return commoditiesObject[commoditySymbol].unitMove;
}

function contractNameDecomposer(name) {
    // console.log("contractNameDecomposer() starting.");
    // console.log("contract: " + name);
    let temp = {};
    if (name != undefined) {
        temp.monthSymbol = name.substring(name.length - 1, name.length);
        temp.commoditySymbol = name.substring(0, name.length - 5);
        temp.year = name.substring(name.length - 5, name.length - 1);
        //  console.log("monthSymbol=" + temp.monthSymbol + "  commoditySymbol=" + temp.commoditySymbol + "\n");
    }
    return temp;
}

const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
        obj[item[keyField]] = item
        return obj
    }, {});

function csiContractToBarchartContract(contract) {
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let year = contractNameDecomposer(contract).year;

    let oneDigitYear = csi_to_barchart_conv(commodity) + month + year.substring(3, 4);
    let fourDigitYear = csi_to_barchart_conv(commodity) + month + year;
    let obj = {oneDigitYear: oneDigitYear, fourDigitYear: fourDigitYear}
   // console.log("obj=", obj);
    return obj;
}

function compareContracts(contract1, contract2) {
    //  console.log("contract1=", contract1, " contract2=", contract2);
    let cND = contractNameDecomposer(contract1);
    let month1 = cND.monthSymbol;
    let month2 = contractNameDecomposer(contract2).monthSymbol;
    let year1 = cND.year;
    let year2 = contractNameDecomposer(contract2).year;

    let compare;
    if (year1 < year2) {
        compare = -1;
    } else if (year1 > year2) {
        compare = 1;
    } else if (month1 < month2) {
        compare = -1;
    } else {
        compare = 1;
    }
    return compare;
}

function generalFormToBarchartTicker(generalForm) {
   // console.log("generalFormToBarchartTicker() starting.");
  //  console.log("generalForm=", generalForm);
    generalForm.barchartTicker = "";

    switch (generalForm.legs) {
        case 1:
           // console.log("1 leg");
            generalForm.spreadP = generalForm.p[0] > 0 ? 1 : -1;
            generalForm.spreadMult = generalForm.mult[0];
            generalForm.barchartTicker = csiContractToBarchartContract(generalForm.selected[0]).oneDigitYear;
            break;
        case 2: //  https://stackoverflow.com/questions/50752987/eslint-no-case-declaration-unexpected-lexical-declaration-in-case-block/50753272
        {
          //  console.log("2 legs");
            let mult = generalForm.mult;
          //  console.log("mult=", mult);
            if (mult[0] === mult[1]) {
                let csiContracts = generalForm.selected[0].split('/');
                let orderedCsiContracts = [...csiContracts].sort(compareContracts);
              //  console.log("csiContracts=", csiContracts);
              //  console.log("orderedCsiContracts=", orderedCsiContracts);

                let orderedP = orderedCsiContracts.map(contract => {
                    let index = csiContracts.indexOf(contract);
                    return generalForm.p[index];
                });
              //  console.log("orderedP=", orderedP);

                generalForm.barchartTicker = "_S_SP_" + orderedCsiContracts.map(x => csiContractToBarchartContract(x).oneDigitYear).join('_');
                generalForm.spreadP = orderedP[0] > 0 ? 1 : -1;
                generalForm.spreadMult = mult[0];
                return generalForm;
            } else {
                generalForm.barchartTicker = "";
            }
            break;
        }
        case 3:
        {
         //   console.log("3 legs");
            let csiContracts = generalForm.selected[0].split('/');
            let orderedCsiContracts = [...csiContracts].sort(compareContracts);
         //   console.log("generalForm.p=", generalForm.p);
         //   console.log("csiContracts=", csiContracts);
         //   console.log("orderedCsiContracts=", orderedCsiContracts);

            let orderedMult = orderedCsiContracts.map(contract => {
                let index = csiContracts.indexOf(contract);
                return generalForm.mult[index];
            });
            let orderedP = orderedCsiContracts.map(contract => {
                let index = csiContracts.indexOf(contract);
                return generalForm.p[index];
            });
          //  console.log("orderedMult=", orderedMult);
          //  console.log("orderedP=", orderedP);


            if (orderedMult[1] === 2 * orderedMult[0] && orderedMult[0] === orderedMult[2] && orderedP[0] === -orderedP[1] && orderedP[0] === orderedP[2]) {
                generalForm.barchartTicker = "_S_BF_" + orderedCsiContracts.map(x => csiContractToBarchartContract(x).oneDigitYear).join('_');
                generalForm.spreadP = orderedP[0] > 0 ? 1 : -1;
                generalForm.spreadMult = orderedMult[0];
                return generalForm;
            } else {
                generalForm.barchartTicker = "";
            }
            break;
        }
        case 4:
        {
            console.log("4 legs");
            let csiContracts = generalForm.selected[0].split('/');
            let orderedCsiContracts = [...csiContracts].sort(compareContracts);
            console.log("generalForm.p=", generalForm.p);
            console.log("csiContracts=", csiContracts);
            console.log("orderedCsiContracts=", orderedCsiContracts);

            let orderedMult = orderedCsiContracts.map(contract => {
                let index = csiContracts.indexOf(contract);
                return generalForm.mult[index];
            });
            let orderedP = orderedCsiContracts.map(contract => {
                let index = csiContracts.indexOf(contract);
                return generalForm.p[index];
            });
            console.log("orderedMult=", orderedMult);
            console.log("orderedP=", orderedP);

            if (orderedMult[0] === orderedMult[1] && orderedMult[1] === orderedMult[2] && orderedMult[2] === orderedMult[3] &&
                    orderedP[1] === -orderedP[0] && orderedP[2] === orderedP[1] && orderedP[3] === -orderedP[2]) {
                generalForm.barchartTicker = "_S_CF_" + orderedCsiContracts.map(x => csiContractToBarchartContract(x).oneDigitYear).join('_');
                generalForm.spreadP = orderedP[0] > 0 ? 1 : -1;
                generalForm.spreadMult = orderedMult[0];
                return generalForm;
            } else {
                generalForm.barchartTicker = "";
            }
            break;
        }
    }
    return generalForm;
}

function csi_to_barchart_conv(symbol) {
    switch (symbol) {
        case "TY":
            return "ZN";

        case "TU":
            return "ZT";

        case "US":
            return "ZB";

        case "FV":
            return "ZF";

        case "AD":
            return "A6";

        case "B":
            return "CB";

        case "BP":
            return "B6";

        case "CB":
            return "BD";

        case "CD":
            return "D6";

        case "DC":
            return "DL";

        case "LH":
            return "HE";

        case "C":
            return "ZC";

        case "EC":
            return "E6";

        case "ED":
            return "GE";

        case "FC":
            return "GF";

        case "GO":
            return "LF";

        case "JY":
            return "J6";

        case "KW":
            return "KE";

        case "LB":
            return "LS";

        case "LC":
            return "LE";

        case "O":
            return "ZO";

        case "RR":
            return "ZR";

        case "SP":
            return "ES";

        case "SM":
            return "ZM";

        case "BO":
            return "ZL";

        case "S":
            return "ZS";

            /*  case "RC":
             return "D";
             */
        case "W":
            return "ZW";

        case "VX":
            return "VI";

        case "SF":
            return "S6";

        default:
            return symbol;

    }
}

function barchart_to_csi_conv(symbol) {
    switch (symbol) {
        case "ZN":
            return "TY";

        case "ZT":
            return "TU";

        case "ZB":
            return "US";

        case "ZF":
            return "FV";

        case "A6":
            return "AD";

        case "CB":
            return "B";

        case "B6":
            return "BP";

        case "BD":
            return "CB";

        case "D6":
            return "CD";

        case "DL":
            return "DC";

        case "HE":
            return "LH";

        case "ZC":
            return "C";

        case "E6":
            return "EC";

        case "GE":
            return "ED";

        case "GF":
            return "FC";

        case "LF":
            return "GO";

        case "J6":
            return "JY";

        case "KE":
            return "KW";

        case "LS":
            return "LB";

        case "LE":
            return "LC";

        case "ZO":
            return "O";

        case "ZR":
            return "RR";

        case "ES":
            return "SP";

        case "ZM":
            return "SM";

        case "ZL":
            return "BO";

        case "ZS":
            return "S";

            /*  case "D":
             return "RC";
             */
        case "ZW":
            return "W";

        case "VI":
            return "VX";

        case "S6":
            return "SF";

        default:
            return symbol;

    }
}

function barchartSpreadToCsiSpread(spread) {
    let originalContracts = spread.split("_").filter(x => x.length > 2)
            .map(x => x.slice(0, -1) + "2" + x.slice(-1))
            .map(y => barchartContractToCsiContract(y));
    // console.log("originalContracts=", originalContracts);

    let csiSpread = originalContracts.join("/");
    return csiSpread;
}

function barchartContractToCsiContract(contract) {
    // console.log("contract=", contract);
    let year = "20" + contract.slice(-2);
    let month = contract.slice(-3, -2);
    let commodity = contract.slice(0, -3);
    let csiContract = barchart_to_csi_conv(commodity) + year + month;
    // console.log("year=", year, " month=", month, " commodity=", commodity + " csiContract=", csiContract);
    return csiContract;
}

function incrementContract(contract, years) {
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let year = contractNameDecomposer(contract).year;
    return commodity + (parseInt(year) + years) + month;
}

function decrementSampleContract(contracts) {
    let newSampleContract = contracts.map(contract => incrementContract(contract, -1));
    return newSampleContract;
}

function ContractWeightedN_tuplets(sampleContract, list) {
  //  console.log("ContractWeightedN_tuplets starting.");
   // console.log("truncated sampleContract=", sampleContract);
   // console.log("list=", list);
    // console.log("openList=", openList);
    let numberOfLegs = sampleContract.length;
    // console.log("numberOfLegs=" + numberOfLegs);
    //let inDatabase;
    /* for (let i = 0; i < list.length; i++) {
     let inDatabase = list[i].includes(sampleContract[i]);
     console.log("inDatabase=", inDatabase);
     if (!inDatabase) {
     console.log("sampleContract[" + i + "]=", sampleContract[i]);
     sampleContract = decrementSampleContract(sampleContract);
     console.log("sampleContract=", sampleContract);
     programVue.$store.commit('generalForm/setSampleContract', sampleContract);
     break;
     }
     }*/

    let offset = [];
    for (let i = 0; i < list.length; i++) {
        let tempOffset = 0;
        for (let j = 0; j < list[i].length; j++) {
            //   console.log("list[i].length=" + list[i].length);
            //   console.log(list[i][j] + "   " + sampleContract[i]);
            if (list[i][j] == sampleContract[i])
                break;
            tempOffset++;
        }
        if (tempOffset == list[i].length) {
            tempOffset = 0;
        }
        offset[i] = tempOffset;
        // console.log("offset[" + i + "]=" + offset[i]);
    }

    let offsetMax = 0;
    let offsetMin = 9999;
    for (let i = 0; i < numberOfLegs; i++) {
        offsetMax = Math.max(offsetMax, offset[i]);
        offsetMin = Math.min(offsetMin, offset[i]);
    }
    // console.log("offsetMax=" + offsetMax + " offsetMin=" + offsetMin);

    // The next loop re-normalizes the offset array.
    let newSampleContract = [];
    for (let i = 0; i < numberOfLegs; i++) {
        newSampleContract.push(list[i][offset[i] - offsetMin]);
        offset[i] = offset[i] - offsetMax;
        // console.log("After renormalization: offsetMax=" + offsetMax + " offset[" + i + "]=" + offset[i]);
    }
    //  console.log("newSampleContract");
    // console.log(newSampleContract);

    let maxNumberOfSpreads = 0;
    for (let i = 0; i < numberOfLegs; i++) {
        let numberOfContracts = list[i].length;
        // console.log("numberOfContracts=" + numberOfContracts);
        maxNumberOfSpreads = Math.max(maxNumberOfSpreads, numberOfContracts);
    }
    // console.log("maxNumberOfSpreads=" + maxNumberOfSpreads);

    let returnArray = [];
    for (let j = 0; j < maxNumberOfSpreads; j++) {
        let rowArray = [];
        let save = true;
        for (let i = 0; i < numberOfLegs; i++) {
            let index = j + offset[i];
            // console.log("i=" + i + " j=" + j + " index=" + index + " list=" + list[i][index]);
            if (list[i][index] == undefined && index >= 0) {
                save = false;
            }
            if (index < 0) {
                rowArray[i] = "---------";
            } else {
                rowArray[i] = list[i][index];
            }
        }
        // console.log("rowArray=", rowArray);

        if (save) {
            returnArray.push(rowArray);
        }
    }

    let returnOpenArray = returnArray.filter(arr => {
      //  console.log("arr=", arr);
        let spread =arr.join('/');
       // console.log("spread=", spread);
       if (!spread.includes("-")){
        let isOpen=isSpreadOpen(spread);
       // console.log("isOpen=", isOpen);

      /*  for (let i = 0; i < numberOfLegs; i++) {
            if (!contracts[i].includes("-") && openList[i].indexOf(contracts[i]) == -1) {
                open = false;
                break;
            }
        }*/
        return isOpen;
    }else{
        return;
    }
    });

  //  console.log("returnOpenArray=", returnOpenArray);
  //  console.log("returnArray=", returnArray);

    let combinedArray = [];
    combinedArray.push(returnArray);
    combinedArray.push(returnOpenArray);
    combinedArray.push(newSampleContract);
    //  console.log("newSampleContract=", newSampleContract);
    //  console.log("ContractWeightedN_tuplets done.");
    return combinedArray;
}

function isSpreadInDatabase(spread) {
    // console.log("spread=", spread);
    let contracts = spread.replace(/\s/g, '').split('/');
    // console.log("contracts=", contracts);
    let filteredContracts = contracts.filter(function (x) {
        try {
            let commodity = contractNameDecomposer(x).commoditySymbol;
            // console.log("commodity=", commodity);
            if (!Contracts(commodity).includes(x)) {
                // console.log("bad contract.")
                return;
            } else {
                //  console.log("OK.")
                return x;
            }
        } catch (err) {
            //  console.log("err=", err);
            console.log("error: ", x, " spread=", spread);
            return;
        }
    });
    //  console.log("filteredContracts=", filteredContracts);

    if (filteredContracts.length === contracts.length) {
        return true;
    } else {
        // console.log("bad contract.");
        return false;
    }
}

function Contracts(commodity, month) {
    //  console.log("commodity=", commodity, "  month=", month);
    if (typeof month !== 'undefined') {
        return commoditiesObject[commodity].months[month].contracts.map(x => x.ticker);
    } else {
        let months = commoditiesObject[commodity].months;
        let contracts = [];
        Object.keys(months).forEach(function (month) {
            contracts = contracts.concat(months[month].contracts.map(x => x.ticker));
        });
        return contracts.sort();
    }
}

function ConstrainSampleContract(contract) {
    console.log("Starting ConstrainSampleContract().");
    // console.trace();
    // console.log("contract =", contract);

    if (intraCommodityArrayTest(contract)) {
        let numberOfContractsOfSeparation = ContractSeparationCounter(contract[0], contract[1]);
        //  console.log("numberOfContractsOfSeparation =", numberOfContractsOfSeparation);

        let commodity = contractNameDecomposer(contract[0]).commoditySymbol;
        let month = contractNameDecomposer(contract[0]).monthSymbol;
        let year0 = contractNameDecomposer(contract[0]).year;

        let index = 0;
        let numberOfMonths = Object.keys(commoditiesObject[commodity].months).length;
        while (Math.abs(numberOfContractsOfSeparation) > numberOfMonths) {
            // console.log("numberOfContractsOfSeparation =", numberOfContractsOfSeparation, " year0=", year0);
            if (numberOfContractsOfSeparation >= 0) {
                year0++;
                numberOfContractsOfSeparation = numberOfContractsOfSeparation - numberOfMonths;
            } else {
                year0--;
                numberOfContractsOfSeparation = numberOfContractsOfSeparation + numberOfMonths;
            }
            index++;
            if (index > 1000)
                break;
        }
        let newContract = commodity + year0 + month;
        contract[0] = newContract;
    }
    // console.log("contract =", contract);
    return contract;
}

function intraCommodityArrayTest(array) {
    let intracommodity = true;
    let commodity = contractNameDecomposer(array[0]).commoditySymbol;
    for (let i = 1; i < array.length; i++) {
        let tempCommodity = contractNameDecomposer(array[i]).commoditySymbol;
        if (tempCommodity !== commodity) {
            intracommodity = false;
            break;
        }
    }
    // console.log("intracommodity =", intracommodity);
    return intracommodity;
}

function ContractSeparationCounter(contract1, contract2) {
    //  console.log("contract1=", contract1, " contract2=", contract2);
    let cND = contractNameDecomposer(contract1);
    let commodity = cND.commoditySymbol;
    let month1 = cND.monthSymbol;
    let month2 = contractNameDecomposer(contract2).monthSymbol;
    let year1 = cND.year;
    let year2 = contractNameDecomposer(contract2).year;

    // console.log("commodity=", commodity, " commoditiesObject=", commoditiesObject);

    let months = Object.keys(commoditiesObject[commodity].months);
    let numberOfMonths = months.length;
    // console.log("months =", months, " numberOfMonths=", numberOfMonths);

    let earlierContract;
    let laterContract;
    let counterSign = 1
    if (year1 < year2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else if (year1 > year2) {
        earlierContract = contract2;
        laterContract = contract1;
        counterSign = -1;
    } else if (month1 < month2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else {
        earlierContract = contract2;
        laterContract = contract1;
        counterSign = -1;
    }
    // console.log("earlierContract =", earlierContract);

    let counter = 0;
    let testContract = earlierContract;
    let testContractMonth = contractNameDecomposer(earlierContract).monthSymbol;
    let testContractMonthIndex = months.indexOf(testContractMonth);
    let testContractYear = contractNameDecomposer(earlierContract).year;
    while (testContract !== laterContract) {
        // console.log("testContractMonth=", testContractMonth, " testContractMonthIndex=", testContractMonthIndex);
        if (testContractMonthIndex == numberOfMonths - 1) {
            testContractYear++;
            testContractMonthIndex = 0;
        } else {
            testContractMonthIndex++;
        }
        testContractMonth = months[testContractMonthIndex];

        testContract = commodity + testContractYear + testContractMonth;
        // console.log("testContract =", testContract);
        counter++;
        if (counter > 300)
            break;
    }
    // console.log("counter =", counter);
    return counterSign * counter;
}

function OpenContracts(commodity, month) {
    if (typeof month !== 'undefined') {
        return commoditiesObject[commodity].months[month].openContracts.map(x => x.ticker);
    } else {
        let months = commoditiesObject[commodity].months;
        let contracts = [];
        Object.keys(months).forEach(function (month) {
            contracts = contracts.concat(months[month].openContracts.map(x => x.ticker));
        });
        return contracts.sort();
    }
}

function decode(text) {
    //  console.log("main.jsp decode():  text=" + text);
    let decodedText;
    if (text == '%') {
        decodedText = "%";
    } else {
        decodedText = decodeURIComponent(text);
        // console.log("decodedText=" + decodedText);
        // decodedText = decodedText.replace(/2F/g, '/');
        decodedText = decodedText.replace(/\+/g, ' ');
        // console.log("decodedText=" + decodedText);
    }
    return decodedText;
}

function getExpiration(contract) {
    // console.log("getExpiration() starting.");
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let contractInCommoditiesObject = commoditiesObject[commodity].months[month].contracts.find(x => (x.ticker === contract));
    // console.log("contractInCommoditiesObject=", contractInCommoditiesObject);

    return typeof contractInCommoditiesObject !== 'undefined' ? contractInCommoditiesObject.expiration : null;
}

function getSpreadExpiration(spread) {
    //  console.log("spread = ", spread);
    let contracts = spread.replace(/\s/g, '').split('/');
    let expiration = Math.min(...contracts.map(contract => getExpiration(contract)));
    return expiration === 0 ? null : expiration;
}

function spreadTitle(generalForm, invertDatesMultiplier = 1, noLegSwapping = false) {
    //console.log("spreadTitle() starting.");
    // console.trace();
    let form = JSON.parse(JSON.stringify(generalForm));
    // console.log("form=", form);

    let contract;
    if (form.instrument === "future") {
        if (typeof form.selected !== 'undefined' && form.selected.length > 0) {
            contract = form.selected[0].replace(/\s/g,'').split("/");
        } else {
            return null;
        }
    } else {
        contract = form.stockArray.slice(0, form.legs).map(x => x.split(',')[0].replace(/\s/g, ''));
    }

    //console.log("contract=", contract);
    /* else {
     contract = form.rsiSource.split("/");
     }*/
    let legs = contract.length;

    let mult = form.mult;
    let p = form.p;
    // console.log("p=" + p);

    if (p[0] < 0 && noLegSwapping === false) {
        let positivePositionIndex = 0;
        while (p[positivePositionIndex] < 0 && positivePositionIndex < legs) {
            positivePositionIndex++;
        }
        //  console.log("positivePositionIndex=", positivePositionIndex, " invertDatesMultiplier=", invertDatesMultiplier);
        if (positivePositionIndex < legs && invertDatesMultiplier == 1) {
            // console.log("Swapping legs.")
            p[0] = 1;
            p[positivePositionIndex] = -1;

            let temp = contract[0];
            contract[0] = contract[positivePositionIndex];
            contract[positivePositionIndex] = temp;

            temp = mult[0];
            mult[0] = mult[positivePositionIndex];
            mult[positivePositionIndex] = temp;
        } else if (legs !== 1) {
            for (let i = 0; i < legs; i++) {
                p[i] = -1 * p[i];
            }
        }
    }
    //console.log("p=" + p);

    let title = "";
    for (let i = 0; i < legs; i++) {
        let position = "+";
        if (p[i] == -1) {
            position = "-";
        }
        // console.log("position=" + position);

        if (mult[i] == 1) {
            title = title + position + " " + contract[i] + " ";
        } else {
            title = title + position + " " + mult[i] + "*" + contract[i] + " ";
        }
    }
    // console.log(title[0]);
    if (title[0] === '+') {
        title = title.substr(1);
    }
    return title.trim();
}

function spreadTitleLegs(generalForm, invertDatesMultiplier = 1, noLegSwapping = false) {
    //console.log("spreadTitleLegs() starting.");
    // console.trace();
    let form = JSON.parse(JSON.stringify(generalForm));
    // console.log("form=", form);

    let contract;
    if (form.instrument === "future") {
        if (typeof form.selected !== 'undefined' && form.selected.length > 0) {
            contract = form.selected[0].split("/");
        } else {
            return null;
        }
    } else {
        contract = form.stockArray.slice(0, form.legs).map(x => x.split(',')[0].replace(/\s/g, ''));
    }
    // console.log("contract=", contract);

    let legs = contract.length;

    let mult = form.mult;
    let p = form.p;
    // console.log("p=" + p);

    if (p[0] < 0 && noLegSwapping === false) {
        let positivePositionIndex = 0;
        while (p[positivePositionIndex] < 0 && positivePositionIndex < legs) {
            positivePositionIndex++;
        }
        //  console.log("positivePositionIndex=", positivePositionIndex, " invertDatesMultiplier=", invertDatesMultiplier);
        if (positivePositionIndex < legs && invertDatesMultiplier == 1) {
            // console.log("Swapping legs.")
            p[0] = 1;
            p[positivePositionIndex] = -1;

            let temp = contract[0];
            contract[0] = contract[positivePositionIndex];
            contract[positivePositionIndex] = temp;

            temp = mult[0];
            mult[0] = mult[positivePositionIndex];
            mult[positivePositionIndex] = temp;
        } else if (legs !== 1) {
            for (let i = 0; i < legs; i++) {
                p[i] = -1 * p[i];
            }
        }
    }
    //console.log("p=" + p);

    let title = "";
    let tickerLegs = [];
    for (let i = 0; i < legs; i++) {
        if (contract[i].indexOf("--") === -1) {
            let position = "+";
            if (p[i] == -1) {
                position = "-";
            }
            // console.log("position=" + position);

            if (mult[i] == 1) {
                title = title + position + " " + contract[i] + " ";
                tickerLegs[i] = position + "" + contract[i];
            } else {
                title = title + position + " " + mult[i] + "*" + contract[i] + " ";
                tickerLegs[i] = position + "" + mult[i] + "*" + contract[i];
            }
        } else {
            tickerLegs[i] = contract[i];
        }
    }
    // console.log(title[0]);
    /*   if (title[0] === '+') {
     title = title.substr(1);
     }*/
    //  console.log("tickerLegs=" + tickerLegs);
    return tickerLegs;
}

function FrontMonthContract(commodity) {
    let contracts = OpenContracts(commodity);
    return contracts[0];
}

function omit(obj, omitKey) {
    return Object.keys(obj).reduce((result, key) => {
        if (key !== omitKey) {
            result[key] = obj[key];
        }
        return result;
    }, {});
}

function commodityName(commoditySymbol) {
    return commoditiesObject[commoditySymbol].name;
}

function commodityMonths(commoditySymbol) {
    return Object.keys(commoditiesObject[commoditySymbol].months);
}

function contractDigits(contract, instrument = "future") {
    // console.log("contract=" + contract);
    let digits;
    if (instrument == "future") {
        let commoditySymbol = contractNameDecomposer(contract).commoditySymbol;
        // console.log("commoditySymbol=" + commoditySymbol);
        digits = commoditiesObject[commoditySymbol].digits;
    } else {
        digits = 2;
    }
    return digits;
}

function spreadDigits(spread, instrument = "future") {
    //  console.log("spreadDigits() starting.");
    let digits;
    if (instrument !== "future") {
        digits = 2;
    } else {
        let sameUnitsAndUnitMoves = areSameUnitsAndUnitMoves(spread);
        // console.log("sameUnitsAndUnitMoves =", sameUnitsAndUnitMoves);
        if (sameUnitsAndUnitMoves) {
            let contracts = spread.replace(/\s/g, '').split('/');
            digits = contractDigits(contracts[0]);
        } else {
            digits = 2;
        }
    }
    return digits;
}

function heightControl(operation, chart) {
    // console.log("am4charts.heightControl() starting.");
    //  console.log("operation=", operation);
    let height = $("#chartdiv").height();
    // console.log("height before adding axis=", height);

    let nominalNumberOfAxes = 0.13 * chart.yAxes.length + 3.5;
    let shim = 0.68;
    // console.log("nominalNumberOfAxes=", nominalNumberOfAxes);

    if (operation === "adding") {
        $("#chartdiv").height(height * (nominalNumberOfAxes + shim) / (nominalNumberOfAxes));
        // console.log("height after adding axis=", $("#chartdiv").height());
        let axisPercentage = 100 / (nominalNumberOfAxes);
        //  console.log("axisPercentage=", axisPercentage);
        return axisPercentage;
    } else {
        $("#chartdiv").height(height * (nominalNumberOfAxes) / (nominalNumberOfAxes + shim));
        //  console.log("height after adding axis=", $("#chartdiv").height());
    }
}

function removeNaNs(array, valueFieldName) {
    // console.log("removeNaNs() starting."); // This also removes weekends and days without data.
    //  console.log("valueFieldName=", valueFieldName);
    // console.log("array=", array);
    let fields = Object.keys(array[0]);
    //  console.log("fields=", fields);
    if (typeof valueFieldName === 'undefined' || fields.indexOf(valueFieldName) === -1) {
        valueFieldName = "close";
    }
    // console.log("valueFieldName=", valueFieldName);

    let returnArray = array.filter(x => !isNaN(x[valueFieldName]));
    //  let nAnArray = array.filter(x => isNaN(x[valueFieldName]));
    // console.log("nAnArray=", nAnArray);
    // console.log("returnArray=", returnArray);
    return returnArray;
}

function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

function getFND(contract) {
    // console.log("contract = ", contract);
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    let contractObject = commoditiesObject[commodity].months[month].openContracts.find(x => (x.ticker === contract));
    // console.log("contractObject = ", contractObject);
    let contractFND;
    if (typeof contractObject !== 'undefined') {
        contractFND = contractObject.fnd;
        // console.log("contractFND = ", contractFND);
    }
    return contractFND;
}

function getSpreadFND(spread) {
    let contracts = spread.replace(/\s/g, '').split('/');
    return Math.min(...contracts.map(contract => getFND(contract)));
}

function tickerToGeneralForm(ticker, instrument = "future") {
    console.log("tickerToGeneralForm() starting. ticker=", ticker);
    // console.trace();
    // console.log("instrument=", instrument);
    let tickerLegs = ticker.replace(/\s/g, '').split(/[.+-/]/);
    let legs = tickerLegs.length;
    console.log("tickerLegs=", tickerLegs);

    let mult = Object.assign([1, 1, 1, 1], tickerLegs.map(x => x.includes('*') ? parseInt(x.split('*')[0]) : 1));
    // console.log("mult=", mult);

    let arr = [...ticker].filter(x => ['-', '+'].includes(x));
    //console.log("arr=", arr);
    if (arr.length < legs) {
        arr.unshift('+');
    }
    let p = Object.assign([1, 1, 1, 1], arr.map(x => x === '+' ? 1 : -1));
    // console.log("p=", p);

    let generalForm = {};
    generalForm.legs = legs;
    generalForm.mult = mult;
    generalForm.p = p;
    let selected = [tickerLegs.map(x => {
            let returnValue = x;
            if (x.includes('*')) {
                // console.log("includes *: ", x);
                returnValue = x.split('*')[1];
            }
            console.log("returnValue=", returnValue);
            return returnValue.replace(/\s/g, '');
        }).join('/')];

    generalForm.selected = selected;
    generalForm.instrument = instrument;

    if (instrument === "future") {
        let contracts = generalForm.selected[0].split('/');
        let contractForPadding = basicChartInitialState.sampleContract.slice(contracts.length, 4);
        console.log("contractForPadding=", contractForPadding);

        generalForm.sampleContract = [...contracts, ...contractForPadding];
        generalForm.unitMove = generalForm.sampleContract.map(contract => getUnitMove(contractNameDecomposer(contract).commoditySymbol));
    } else {
        generalForm.symbolArray = generalForm.selected[0].split('/');
        generalForm.unitMove = [1, 1, 1, 1];
    }
    //console.log("generalForm=", generalForm);
    return generalForm;
}

function getExchange(commodity) {
    let group = getGroup(commodity);
    // console.log("group=", group);

    switch (group) {
        case "Grains":
            if (commodity == "KW") {
                return "KCBT";
            }
            if (commodity == "MW") {
                return "MGEX";
            } else {
                return "CBOT";
            }
        case "Energies":
            return "NYMEX";
        case "Metals":
            if (["PA", "PL"].includes(commodity)) {
                return "NYMEX";
            } else {
                return "COMEX";
            }
        case "Interest Rates":
            if (commodity == "ED") {
                return "CME";
            } else {
                return "CBOT";
            }
        case "Currencies":
            if (["EC", "DX"].includes(commodity)) {
                return "ICEUS";
            } else {
                return "CME";
            }
        case "Softs and Fibers":
            if (commodity == "SW") {
                return "LCE";
            }
            if (["CB", "LB"].includes(commodity)) {
                return "CME";
            } else {
                return "ICEUS";
            }
        case "Indices":
            if (commodity == "VX") {
                return "CFE";
            }
            if (["NQ", "SP"].includes(commodity)) {
                return "GBLX";
            } else {
                return "CBOTM";
            }
        case "Meats":
            return "CME";
    }
}

function isContractOpen(contract) {
    let commodity = contractNameDecomposer(contract).commoditySymbol;
    let month = contractNameDecomposer(contract).monthSymbol;
    return OpenContracts(commodity, month).includes(contract);
}

function isSpreadOpen(spread) {
   // console.log("isSpreadOpen() starting. spread=", spread);
    let contracts = spread.replace(/\s/g, '').split('/');
    return !contracts.some(x => !isContractOpen(x));
}

let groupsObject = {"Currencies": {"commodities": ["AD", "BP", "CD", "EC", "JY", "SF", "DX"], "name": "Currencies"},
    "Energies": {"commodities": ["B", "CL", "GO", "RB", "HO", "NG"], "name": "Energies"},
    "Grains": {"commodities": ["C", "KW", "MW", "O", "SM", "BO", "S", "W", "RR"], "name": "Grains"},
    "Indices": {"commodities": ["YM", "NQ", "SP", "VX"], "name": "Indices"},
    "Interest Rates": {"commodities": ["TY", "TU", "US", "FV", "ED", "ZQ"], "name": "Interest Rates"},
    "Meats": {"commodities": ["FC", "LH", "LC"], "name": "Meats"},
    "Metals": {"commodities": ["HG", "GC", "PA", "PL", "SI"], "name": "Metals"},
    "Softs and Fibers": {"commodities": ["CB", "DC", "CC", "KC", "CT", "LB", "OJ", "RC", "SB", "SW"], "name": "Softs and Fibers"}};

function getGroup(commodity) {
    let returnGroup = "";
    let groups = Object.keys(groupsObject);
    groups.forEach(function (group) {
        let commodities = groupsObject[group].commodities;
        // console.log(group + ": " + commodities);
        let isInGroup = commodities.includes(commodity);
        if (isInGroup) {
            returnGroup = group;
        }

    });
    return returnGroup;
}

function contractUnits(contract) {
    // console.log("contractUnits() starting. contract=" + contract);
    let commoditySymbol = contractNameDecomposer(contract).commoditySymbol;
    return commoditiesObject[commoditySymbol].units;
}

function spreadUnits(spread, instrument = "future") {
    // console.log("spreadUnits() starting. spread=" + spread);
    let units;
    if (instrument !== "future") {
        units = "USD";
    } else {
        let sameUnitsAndUnitMoves = areSameUnitsAndUnitMoves(spread);
        // console.log("sameUnitsAndUnitMoves =", sameUnitsAndUnitMoves);
        if (sameUnitsAndUnitMoves) {
            let contracts = spread.replace(/\s/g, '').split('/');
            units = contractUnits(contracts[0]);
        } else {
            units = "USD";
        }
    }
    return units;
}

function getDaysArray(start, end) {
    let arr = [];
    for (let dt = moment(start).add(-1, 'days'); dt <= end; dt.add(1, 'days')) {
        arr.push(moment(dt));
    }
    return arr;
}

function getBarchartMultsAndPositionsArray(legs, spreadP = 1) {
    console.log("getBarchartMultsAndPositionsArray() starting. spreadP=", spreadP);
    // console.log("barchartMultsAndPositionsMatrix =", barchartMultsAndPositionsMatrix);
    let barchartMultsAndPositionsMatrix = [
        {legs: 1, p: [1, -1, 1, 1], mult: [1, 1, 1, 1]},
        {legs: 2, p: [1, -1, 1, 1], mult: [1, 1, 1, 1]},
        {legs: 3, p: [1, -1, 1, 1], mult: [1, 2, 1, 1]},
        {legs: 4, p: [1, -1, -1, 1], mult: [1, 1, 1, 1]}
    ];

    let barchartMultsAndPositionsArray = barchartMultsAndPositionsMatrix.find(x => x.legs === legs);
    console.log("barchartMultsAndPositionsArray=", barchartMultsAndPositionsArray);
    barchartMultsAndPositionsArray.p.forEach((p, index, arr) => {
        arr[index] = p * spreadP;
        console.log("p=", p, "arr=", arr);
    });
    console.log("barchartMultsAndPositionsArray=", barchartMultsAndPositionsArray);
    return barchartMultsAndPositionsArray;
}

function hexToRgb(hex) {
    let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
    } : null;
}

function transpose(a) {
    return Object.keys(a[0]).map(function (c) {
        return a.map(function (r) {
            return r[c];
        });
    });
}

function orderContracts(contract1, contract2) {
    //  console.log("contract1=", contract1, " contract2=", contract2);
    let cND = contractNameDecomposer(contract1);
    let month1 = cND.monthSymbol;
    let month2 = contractNameDecomposer(contract2).monthSymbol;
    let year1 = cND.year;
    let year2 = contractNameDecomposer(contract2).year;

    let earlierContract;
    let laterContract;
    if (year1 < year2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else if (year1 > year2) {
        earlierContract = contract2;
        laterContract = contract1;
    } else if (month1 < month2) {
        earlierContract = contract1;
        laterContract = contract2;
    } else {
        earlierContract = contract2;
        laterContract = contract1;
    }
    return [earlierContract, laterContract];
}

const kebabize = str => {
    return str.split('').map((letter, idx) => {
      return letter.toUpperCase() === letter
       ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
       : letter;
    }).join('');
 }

export {areSameUnits, areSameUnitMoves, areSameUnitsAndUnitMoves, commodityUnits,
        contractNameDecomposer, getUnitMove, arrayToObject, csi_to_barchart_conv, barchartSpreadToCsiSpread,
        barchartContractToCsiContract, incrementContract, decrementSampleContract, ContractWeightedN_tuplets, isSpreadInDatabase,
        ConstrainSampleContract, intraCommodityArrayTest, ContractSeparationCounter, Contracts, OpenContracts, setCommoditiesObject,
        getCommoditiesObject, decode, getSpreadExpiration, spreadTitle, spreadTitleLegs, FrontMonthContract, omit, commodityName, 
        spreadDigits, heightControl, removeNaNs, capitalizeFirstLetter, getSpreadFND, tickerToGeneralForm, getExchange, spreadUnits,
        isSpreadOpen, generalFormToBarchartTicker, getDaysArray, getBarchartMultsAndPositionsArray, hexToRgb, commodityMonths, 
        csiContractToBarchartContract, transpose, orderContracts, kebabize};
