import Vue from "vue";
import Vuex from "vuex";

import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import user from "./user";
import authentication from "./authentication";
import generalFormTab1 from "./generalFormModule";
import generalFormTab2 from "./generalFormModule";
import generalFormTab3 from "./generalFormModule";
import generalFormTab4 from "./generalFormModule";
import siteData  from "./siteDataModule";
//import user from './user';
import settings from './settings';
import {setCommoditiesObject/*, arrayToObject*/} from "../js/main";
import ApiService from "@/core/services/api.service";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    htmlClass,
    config,
    breadcrumbs,
    user,
    authentication,
    settings,
    siteData,
    generalFormTab1,
    generalFormTab2,
    generalFormTab3,
    generalFormTab4
  },
  state: {
      activeModuleIndex: null,
      hideInput: false,
      showStateEditor: false
  },
  getters: {
      moduleArray(state) {
          // console.log("state=", state);
          // console.log("Object.values(state)=", Object.values(state));
          let arr = Object.values(state)
                  .filter(module => module !== null && typeof module.moduleName !== 'undefined'
                   && module.moduleName !== null && module.moduleName.includes("generalFormTab"));

          // console.log("arr=", arr);
          return arr;
      },
      activeModuleName(state, getters) {
          console.log("activeModuleName getter starting.");
          let moduleArray = getters['moduleArray'];

          if (typeof moduleArray[state.activeModuleIndex] !== 'undefined') {
              let activeModuleName = moduleArray[state.activeModuleIndex].moduleName;
              console.log("activeModuleName=", activeModuleName);
              return activeModuleName;
          } else {
              return null;
          }
      },
      activeModuleId(state, getters) {
          if (typeof getters['activeModuleName'] !== 'undefined' && getters['activeModuleName'] !== null) {
              //  let activeModuleName = this.$store.getters['activeModuleName'];
              return getters['activeModuleName'].replace("generalFormTab", "");
          } else {
              return null;
          }
      },
      nextModuleId(state, getters) {
          let nextModuleId;
          let moduleArray = getters['moduleArray'];
          if (moduleArray.length === 0) {
              nextModuleId = 0;
          } else {
              let lastModule = JSON.parse(JSON.stringify(moduleArray[moduleArray.length - 1]));
            //  console.log("lastModule.moduleName=", lastModule.moduleName);

              let lastModuleId = lastModule.moduleName.replace('generalFormTab', '');
            //  console.log("lastModuleId=", lastModuleId);
              nextModuleId = parseInt(lastModuleId) + 1;
          }
          return nextModuleId;
      }
  },
  mutations: {
      setActiveModuleIndex: function (state, activeModuleIndex) {
          console.log("setActiveModuleIndex() starting. activeModuleIndex=", activeModuleIndex);
          state.activeModuleIndex = activeModuleIndex;
      }, 
      setShowStateEditor: function (state, showStateEditor) {
        state.showStateEditor = showStateEditor;
    }
  },
  actions: {
      getCommodities(/* { commit }*/) {
          return new Promise((resolve) => {
            ApiService.get("https://d12rqyzaltchhs.cloudfront.net/commoditiesObjectWithExpirations.js").then(response => {
              // console.log("response.data=", response.data);
                let commoditiesObject = JSON.parse(response.data
                                             .replace("function getCommoditiesWithExpirationsObject(){return", "").slice(0, -1));
                setCommoditiesObject(commoditiesObject);
                resolve({...commoditiesObject});
             });
          });
      }
  }
})
