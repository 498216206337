import * as fb from '../firebase.js'
import router from '../router.js'
import store from '@/store';

/*
const state = {
  userProfile: {}
};

export const getters = {
  getUserProfile(state) {
    return state.userProfile;
  },
  isAuthenticated(state) {
    return !!state.userProfile;
  }
};

export const mutations = {
  setUserProfile(state, val) {
    // console.trace();
    console.log("setUserProfile() starting. val=", val, " state=", state);
    state.userProfile = val;
  }
};
*/

export const actions = {
  /* async login({ commit, dispatch }, payload) {
     console.log("login() starting. payload=", payload);
     commit('setLoading', true);
     await fb.auth.signInWithEmailAndPassword(payload.email, payload.password)
       .then(firebaseData => {
         dispatch('fetchUserProfile', firebaseData.user);
         commit('setLoading', false);
         commit('setError', null);
       })
       .catch(error => {
         commit('setLoading', false);
         commit('setError', { login: error });
       });
   },*/
  async logout({ commit }) {
    await fb.auth.signOut()
    commit('user/SET_STATE', {});
    router.currentRoute.path !== '/auth' && router.push('/auth');
  },
  /* async signup({ commit }, payload) {
     console.log("signup() starting. payload=", payload);
     commit('setLoading', true);
     const userCredential = await fb.auth.createUserWithEmailAndPassword(payload.email, payload.password);
     console.log("userCredential=", userCredential);
 
     let unsubscribe = fb.usersCollection.doc(payload.email).onSnapshot((doc) => {
       console.log("Current data: ", doc.data());
 
       fb.usersCollection.doc(payload.email).update({
         name: payload.name
       }).then(() => {
         unsubscribe();  // Not sure if this is proper.
         commit('setLoading', false);
         commit('setInformation', { signUp: { code: 'Success', message: `User created!, use your new credentials` } });
         commit('setEmail', null);
         commit('setName', null);
       })
     })
   },*/
  async fetchUserProfile({ commit }, user) {
   // console.log("async fetchUserProfile() starting. user=", user);
   // commit('setLoading', true);

    return new Promise((resolve) => {
      if (store.state.user.role !== '') {
        console.log("Getting userInfo from the store.");
        let userInfo = { role: store.state.user.role, email: store.state.user.email, name: store.state.user.name, subscriptions: store.state.user.subscriptions};
      //  console.log("userInfo=", userInfo);
        resolve(userInfo);
      } else {
      //  console.log("Checking firebase.");
        fb.usersCollection.doc(user.email).get()
          .then(firebaseData => {
            const userInfo = firebaseData.data();
          //  console.log("userInfo=", userInfo);

            if (userInfo!=undefined) {
              //!userInfo.enable && dispatch('logout');
             // console.log("loading user info");
              commit('user/setEmail', userInfo.email);
              commit('user/setName', userInfo.name);
              commit('user/setRole', userInfo.role);
              commit('user/setSubscriptions', userInfo.subscriptions);
             // commit('setLoading', false);
             // commit('setError', null);
            }
            resolve(userInfo);
          })
          .catch(error => {
            commit('setError', error)
            commit('setLoading', false);
          });
      }
    });
  },
  async resetPassword({ commit }, payload) {
    commit('setLoading', true);
    await fb.auth
      .sendPasswordResetEmail(payload.email)
      .then(() => {
        commit('setLoading', false);
        commit('setInformation', { resetPassword: { code: 'Success', message: 'Success!, check your email for the password reset link' } });
        commit('setError', null);
      })
      .catch((error) => {
        commit('setLoading', false);
        commit('setInformation', null);
        commit('setError', { resetPassword: error });
      })
  }
};

export default {
 // state,
 // getters,
 // mutations,
  actions
};
