import browserSideOnly from "./browserSideOnlyModule";
import chartParameters from "./chartParametersModule";
import playback from "./playbackModule";
import search from "./searchModule";
import moment from 'moment';
import {getCommoditiesObject, ConstrainSampleContract, contractNameDecomposer, isSpreadInDatabase, 
    decrementSampleContract, getUnitMove, Contracts, OpenContracts, ContractWeightedN_tuplets, spreadTitle } from "../js/main";

export default {
    namespaced: true,
    modules: {
        browserSideOnly, chartParameters, playback, search
    },

    // State must be a function so we can re-use this module across multiple routes
    state: () => ({
        moduleName: null,
        instrument: null,
        legs: null,
        sampleContract: [],
        unitMove: [],
        selected: [],
        intracommodity: null,
        p: [],
        mult: [],
        y1: null,
        generator: null,
        program: null,
        stockGraphType: null,
        seasonals: [],
        dataSource: null,
        spreadP: 1,
        spreadMult: 1,
        stockArray: [],
        loading: false,
        addRSIPanel: null,
        rsiPeriod: null,
        addCCIPanel: null,
        cciPeriod: null,
        cciSource: null,
        addMACDPanel: null,
        addATRPanel: null,
        addBollinger: null,
        addSMA: null,
        smaLength: null,
        addVolumePanel: null,
        showBullets: null,
        addCOTPanel: null,
        addVolumeProfile: null,
        volumeProfileColumns: null,
        showTradingPeriod: null,
        showPlaybackControl: null,
        showBreakpoints: null,
        addProfitLoss: null,
        addHorizontalLine: null,
        open: null,
        close: null,
        study: null,
        tabTitle: null,
        hideInput: false,
        barchartTicker: null,
        truncate: null,
        numberOfContractsApart: null,
        normalization: null,
        normalizationMonth: null,
        normalizationDate: null,
        forwardCurveStudy: null,
        constrainContractAligner: null,
        user: null,
        buySell: null
    }),
    getters: {
        openMonth(state) {
            return moment(state.open).locale("en").format("MMMM");
        },
        openDate(state) {
            return moment(state.open).locale("en").format("D");
        },
        closeMonth(state) {
            return moment(state.close).locale("en").format("MMMM");
        },
        closeDate(state) {
            return moment(state.close).locale("en").format("D");
        },
        cciSourceOptions(state) {
            let options = [
                { value: "closes", text: "closes" },
                { value: "typical", text: "typical" }
            ];
            if (state.stockGraphType === "line") {
                options = [{ value: "closes", text: "closes" }];
                state.cciSource = "closes";
            }
            return options;
        },
        c(state) {
            let commodity = [];
            for (let i = 0; i < state.sampleContract.length; i++) {
                commodity.push(contractNameDecomposer(state.sampleContract[i]).commoditySymbol);
            }
            return commodity;
        },
        m(state) {
            let month = [];
            for (let i = 0; i < state.sampleContract.length; i++) {
                month.push(contractNameDecomposer(state.sampleContract[i]).monthSymbol);
            }
            return month;
        },
        ticker(state) {
            // console.log("ticker getter starting.");
            let generalForm = JSON.parse(JSON.stringify(state));
            //  console.log("state=", generalForm);
            let ticker;
            if (typeof state.selected !== 'undefined' && state.selected.length > 0) {
                ticker = spreadTitle(generalForm);
            } else if (["Calculator", "OpenSpreads"].includes(state.program)) {
                let temp = generalForm.sampleContract.slice(0, state.legs);
                // console.log("temp=", temp);
                generalForm.selected = [temp.join('/')];
                ticker = spreadTitle(generalForm);
            } else {
                return null;
            }
            // console.log("ticker=", ticker);
            return ticker;
        },
        tickerNoLegSwapping(state) {
            //  console.log("ticker getter starting.");
            //  console.log("state=", JSON.parse(JSON.stringify(state)));
            if (typeof state.selected !== 'undefined' && state.selected.length > 0) {
                let noLegSwapping = true;
                let invertDatesMultiplier = 1;
                let ticker = spreadTitle(JSON.parse(JSON.stringify(state)), invertDatesMultiplier, noLegSwapping);
                // console.log("ticker=", ticker);
                return ticker;
            } else {
                return null;
            }
        },
        listOptions(state) {
            var returnArray = [];
          //  let alignerOpenOptions = state.browserSideOnly.alignerOpenOptions;
          //  console.log("alignerOpenOptions=", alignerOpenOptions)

            let alignerOpenSpreads = state.browserSideOnly.alignerOpenOptions.map(x => x.join('/'));
          //  console.log("alignerOpenSpreads=", alignerOpenSpreads)

            state.browserSideOnly.alignerOptions.map(optionArray => {
                let optionSpread = optionArray.join('/');
               // console.log("optionSpread=", optionSpread)
                if (optionSpread.indexOf("-") == -1) {
                    //  console.log("optionArray=", optionArray)
                    var open = alignerOpenSpreads.includes(optionSpread); /*&& ["seasonal", "history"].includes(state.study); */
                    returnArray.push({ value: optionSpread, open: open });
                }
            });
            return returnArray;
        },
        listTickerOptions(state, getters) {
            var returnArray = [];
            //  console.log("state.browserSideOnly.alignerOptions=", state.browserSideOnly.alignerOptions);

            getters['browserSideOnly/tickerOptions'].map(function (temp) {
                //console.log("temp =", temp);
                var option = temp.join('');
                if (option.indexOf("--") == -1) {
                    var open = true; //state.browserSideOnly.openOptions.includes(option); /*&& ["seasonal", "history"].includes(state.study); */
                    returnArray.push({ value: option, open: open });
                    // console.log("returnArray =", returnArray);
                }
            });
            return returnArray;
        },
        seasonalOptions(state, getters) {
            // console.log("seasonalOptions() starting.");
            let seasonalColors = ["orange", "darksalmon", "crimson", "brown", "black"];

            let closedOptions = getters['listOptions'].filter(x => !x.open);
            let maxYears = Math.min(30, closedOptions.length);

            var possibleOptions = [5, 10, 15, 20, 30];
            let options = possibleOptions.filter(x => x <= maxYears);

            let maxOption = Math.max(...options);
            //  console.log("maxOption=", maxOption);
            if (maxOption < maxYears) {
                options.push(maxYears);
            }

            // console.log("state.browserSideOnly.minUnreturnedSeasonal=", state.browserSideOnly.minUnreturnedSeasonal);
            let returnableOptions = options.filter(x => x < state.browserSideOnly.minUnreturnedSeasonal)
                .map((value, index) => ({ value: value, color: seasonalColors[index] }));
            // console.log("returnableOptions =", returnableOptions);

            // state.commit('setSeasonals', returnableOptions);
            return returnableOptions;
        }
    },
    mutations: {
        setGeneralForm: function (state, newState) {
            Object.assign(state, newState);
        },
        setSampleContract: function (state, sampleContract) {
            state.sampleContract = sampleContract;
        },
        setLegs: function (state, legs) {
            state.legs = legs;
        },
        setUnitMove: function (state, unitMove) {
            state.unitMove = unitMove;
            // state.playback.i = null;
        },
        setSelected: function (state, selected) {
            state.selected = selected;
            // state.playback.i = null;
        },
        setY1: function (state, y1) {
            state.y1 = y1;
        },
        setP: function (state, p) {
            state.p = p;
            // state.playback.i = null;
        },
        setInstrument: function (state, instrument) {
            state.instrument = instrument;
        },
        setIntracommodity: function (state, intracommodity) {
            state.intracommodity = intracommodity;
        },
        setMult: function (state, mult) {
            state.mult = mult;
        },
        setStudy: function (state, study) {
            state.study = study;
        },
        setStockGraphType: function (state, stockGraphType) {
            state.stockGraphType = stockGraphType;
        },
        setSeasonals: function (state, seasonals) {
            state.seasonals = seasonals;
        },
        setAddRSIPanel: function (state, addRSIPanel) {
            state.addRSIPanel = addRSIPanel;
        },
        setRsiPeriod: function (state, rsiPeriod) {
            state.rsiPeriod = rsiPeriod;
        },
        setAddCCIPanel: function (state, addCCIPanel) {
            state.addCCIPanel = addCCIPanel;
        },
        setCciPeriod: function (state, cciPeriod) {
            state.cciPeriod = cciPeriod;
        },
        setCciSource: function (state, cciSource) {
            state.cciSource = cciSource;
        },
        setAddMACDPanel: function (state, addMACDPanel) {
            state.addMACDPanel = addMACDPanel;
        },
        setAddATRPanel: function (state, addATRPanel) {
            state.addATRPanel = addATRPanel;
        },
        setAddBollinger: function (state, addBollinger) {
            state.addBollinger = addBollinger;
        },
        setAddSMA: function (state, addSMA) {
            state.addSMA = addSMA;
        },
        setSmaLength: function (state, smaLength) {
            state.smaLength = smaLength;
        },
        setAddVolumePanel: function (state, addVolumePanel) {
            state.addVolumePanel = addVolumePanel;
        },
        setShowBullets: function (state, showBullets) {
            state.showBullets = showBullets;
        },
        setShowPlaybackControl: function (state, showPlaybackControl) {
            state.showPlaybackControl = showPlaybackControl;
        },
        setShowBreakpoints: function (state, showBreakpoints) {
            state.showBreakpoints = showBreakpoints;
        },
        setAddCOTPanel: function (state, addCOTPanel) {
            state.addCOTPanel = addCOTPanel;
        },
        setDataSource: function (state, dataSource) {
            state.dataSource = dataSource;
        },
        setSpreadP: function (state, spreadP) {
            state.spreadP = spreadP;
        },
        setSpreadMult: function (state, spreadMult) {
            state.spreadMult = spreadMult;
        },
        setLoading: function (state, loading) {
            state.loading = loading;
        },
        setStockArray: function (state, stockArray) {
            state.stockArray = stockArray;
        },
        setProgram: function (state, program) {
            state.program = program;
        },
        setModuleName: function (state, moduleName) {
            state.moduleName = moduleName;
        },
        setAddVolumeProfile: function (state, addVolumeProfile) {
            state.addVolumeProfile = addVolumeProfile;
        },
        setVolumeProfileColumns: function (state, volumeProfileColumns) {
            state.volumeProfileColumns = volumeProfileColumns;
        },
        setShowTradingPeriod: function (state, showTradingPeriod) {
            state.showTradingPeriod = showTradingPeriod;
        },
        setAddProfitLoss: function (state, addProfitLoss) {
            state.addProfitLoss = addProfitLoss;
        },
        setAddHorizontalLine: function (state, addHorizontalLine) {
            state.addHorizontalLine = addHorizontalLine;
        },
        setOpen: function (state, open) {
            state.open = open;
        },
        setClose: function (state, close) {
            state.close = close;
        },
        setOpenMonth: function (state, openMonth) {
            state.openMonth = openMonth;
        },
        setOpenDate: function (state, openDate) {
            state.openDate = openDate;
        },
        setCloseMonth: function (state, closeMonth) {
            state.closeMonth = closeMonth;
        },
        setCloseDate: function (state, closeDate) {
            state.closeDate = closeDate;
        },
        setTabTitle: function (state, tabTitle) {
            state.tabTitle = tabTitle;
        },
        setHideInput: function (state, hideInput) {
            state.hideInput = hideInput;
        },
        setBarchartTicker: function (state, barchartTicker) {
            state.barchartTicker = barchartTicker;
        },
        setTruncate: function (state, truncate) {
            state.truncate = truncate;
        },
        setNumberOfContractsApart: function (state, numberOfContractsApart) {
            state.numberOfContractsApart = numberOfContractsApart;
        },
        setNormalization: function (state, normalization) {
            state.normalization = normalization;
        },
        setNormalizationMonth: function (state, normalizationMonth) {
            state.normalizationMonth = normalizationMonth;
        },
        setNormalizationDate: function (state, normalizationDate) {
            state.normalizationDate = normalizationDate;
        },
        setForwardCurveStudy: function (state, forwardCurveStudy) {
            state.forwardCurveStudy = forwardCurveStudy;
        },
        setConstrainContractAligner: function (state, constrainContractAligner) {
            state.constrainContractAligner = constrainContractAligner;
        },
        setUser: function (state, user) {
            state.user = user;
        },
        setBuySell: function (state, buySell) {
            state.buySell = buySell;
        }
    },
    actions: {
        shiftSampleContract({ getters, commit, state, dispatch }, payload) {
            console.log("shiftSampleContract() starting.");
            // console.log("state=", state);
            var index = payload.index;
            console.log("index=", index);
            var oldContract = state.sampleContract[index];
            var temp = contractNameDecomposer(oldContract);
            var newContract = temp.commoditySymbol + parseInt(parseInt(temp.year) - 1) + temp.monthSymbol;
            console.log("index=" + index + " oldContract=" + oldContract + " newContract=" + newContract);

            var earliestContractIndex = state.browserSideOnly.contractLists[index].length - 1;
            var earliestContract = state.browserSideOnly.contractLists[index][earliestContractIndex];
            // console.log("earliestContract=" + earliestContract);
            var earliestYear = contractNameDecomposer(earliestContract).year;
            if (temp.year > earliestYear) {
                var arr = state.sampleContract.slice(0);  //, this.$store.state.legs); //slice(0) 
                arr[index] = newContract;
                //  this.$store.commit('setSampleContract', arr);
                var results = ContractWeightedN_tuplets(arr.slice(0, state.legs), state.browserSideOnly.contractLists.slice());
                console.log("results[1]=", results[1]);
                console.log("results[2]=", results[2]);
                for (let i = 0; i < state.legs; i++) {
                    arr[i] = results[2][i];
                }
                console.log("arr=", arr);
                commit('setSampleContract', arr);

                commit('browserSideOnly/setAlignerOptions', results[0]);
                commit('browserSideOnly/setAlignerOpenOptions', results[1]);

                let openSpreads = getters['listOptions'].filter(x => x.open === true);
                console.log("last openSpreads=", openSpreads[openSpreads.length - 1]);

                //  let openSpreads = this.$store.getters['generalForm/listOptions'].filter(x => x.open === true);
                // commit('setRsiSource', openSpreads[1][openSpreads[1].length - 1]);
                commit('setSelected', []);
                // dispatch('hideOpenContracts');
                commit('browserSideOnly/setMinUnreturnedSeasonal', 40);
                dispatch('setOptionColors');

                // updateChart();
            }
        },
        changeCommodityLeg({ commit, dispatch, state }, payload) {
            console.log("changeCommodityLeg() starting.");
          //  console.trace();
            console.log("payload =", payload);
            var commodity = payload.commodity;
            var index = payload.index;
            console.log("commodity =", commodity);
            console.log("index =", index);
            var months = Object.keys(getCommoditiesObject()[commodity].months);
            console.log("months =", months);
            var year = contractNameDecomposer(state.sampleContract[0]).year;
            var newUnitMove = getCommoditiesObject()[commodity].unitMove;
            var newSampleContractArray = [];
            var newUnitMoveArray = [];
            var newContract;
            if (JSON.parse(state.intracommodity) === false) {
                newSampleContractArray = state.sampleContract.slice(0);
                newContract = commodity + year + months[index];
                if (!isSpreadInDatabase(newContract)) {
                    newContract = decrementSampleContract([newContract])[0];
                }
                console.log("newContract =", newContract);
                newSampleContractArray[index] = newContract;

                if (typeof state.unitMove !== 'undefined') {
                    newUnitMoveArray = state.unitMove.slice(0);
                } else {
                    newUnitMoveArray = newSampleContractArray.slice(0).map(x => getUnitMove(contractNameDecomposer(x).commoditySymbol));
                    console.log("newUnitMoveArray =", newUnitMoveArray);
                }
                newUnitMoveArray[index] = newUnitMove;
            } else {
                for (var i = 0; i < 4; i++) {
                    newContract = commodity + year + months[i];
                    if (!isSpreadInDatabase(newContract)) {
                        newContract = decrementSampleContract([newContract])[0];
                    }
                    console.log("newContract =", newContract);
                    newSampleContractArray.push(newContract);
                    newUnitMoveArray.push(newUnitMove);
                }
            }
            newSampleContractArray = ConstrainSampleContract(newSampleContractArray);
            console.log("newSampleContractArray =", newSampleContractArray);
            commit('setSampleContract', newSampleContractArray);
            commit('setUnitMove', newUnitMoveArray);
            // commit('setRsiSource', "");
            commit('setSelected', []);
            commit('setNumberOfContractsApart', 0);

            dispatch('getContractListsFromServer', null);
        },
        checkPositions({ commit, state }, index = 0) {
           // console.log("checkPositions() starting. legs=" + state.legs);
            //  console.log("state.generator=", state.generator);
            //  console.log("state.p=", state.p);

            var newArray = state.p.slice(0);
            /*   if (state.legs === 1 && !(state.generator === "SeasonalsGenerator" && state.study === "calculator")) {
             newArray[0] = 1;
             } else */ if (state.legs == 2) {
                if (index == 0) {
                    newArray[1] = -state.p[0];
                }
                if (index == 1) {
                    newArray[0] = -state.p[1];
                }
            }
            commit('setP', newArray);
            // console.log("state.p=", state.p);
        },
        getContractListsFromServer({ commit, dispatch, state }) {
           // console.log("getContractListsFromServer() starting.");
            // console.trace();
            //  console.log("rootState.generalForm.legs=", rootState.generalForm.legs);
            // console.log("state.sampleContract=", state.sampleContract);
            //  commit('generalForm/browserSideOnly/setMinUnreturnedSeasonal', 40);
            commit('setSpreadMult', 1);

            // var constrainedContract = ConstrainSampleContract(state.sampleContract);
            var constrainedContract = [...state.sampleContract];
            // console.log("constrainedContract=", constrainedContract);

            var contractsArray = [];
            var openContractsArray = [];
            for (let i = 0; i < state.legs; i++) {
                let commodity = contractNameDecomposer(state.sampleContract[i]).commoditySymbol;
                let month = contractNameDecomposer(state.sampleContract[i]).monthSymbol;
                let contracts = Contracts(commodity, month);
                let openContracts = OpenContracts(commodity, month);
                contractsArray.push(contracts);
                openContractsArray.push(openContracts);
            }
            // console.log("contractsArray=", contractsArray);
            // console.log("openContractsArray=", openContractsArray);

            commit('browserSideOnly/setContractLists', contractsArray);
            commit('browserSideOnly/setOpenContractLists', openContractsArray);
            var newResults = ContractWeightedN_tuplets(constrainedContract.slice(0, state.legs), contractsArray.slice());
         //   console.log("newResults[0]=", newResults[0]);
         //   console.log("newResults[1]=", newResults[1]);
         //   console.log("newResults[2]=", newResults[2]);

            commit('browserSideOnly/setAlignerOptions', newResults[0]);
            commit('browserSideOnly/setAlignerOpenOptions', newResults[1]);

            var arr = state.sampleContract.slice(0);
            // console.log("arr=", [...arr]);
            for (let i = 0; i < state.legs; i++) {
                arr[i] = newResults[2][i];
            }
            // console.log("arr=", arr);
            commit('setSampleContract', arr);
            //   dispatch('displaySelectedOpenOptionsCorrectly');  // Apparently, this statement is not needed.

            dispatch('setOptionColors');
        },
        setOptionColors({ commit, state }) {
            // console.log("setOptionColors() starting.");
            // let contractSeparation = ContractSeparationCounter(state.sampleContract[0], state.sampleContract[1]);
            // console.log("Math.sign(contractSeparation)=", Math.sign(contractSeparation));
            commit('browserSideOnly/setNumberOfIncompleteSpreads', 0);
            let tickerOptions = state.browserSideOnly.alignerOptions;
            //  console.log("tickerOptions =", tickerOptions);
            if (tickerOptions !== null) {
                tickerOptions.map(function (temp) {
                    // console.log("temp ", temp);
                    if (temp.join().indexOf("--") > -1) {
                        commit('browserSideOnly/incrementNumberOfIncompleteSpreads');
                    }
                });
            }
            //console.log("numberOfIncompleteSpreads", state.browserSideOnly.numberOfIncompleteSpreads);
        }
    }
};


