export default {
    namespaced: true,
    state: () => ({
            chartType: null,
            amchartsChartType: null,
            dataType: null,
            grouping: null,
            legsPanel: null,
            legendLocation: null,
            balloons: null,
            lineWidth: null,
            height: null,
            width: null,
            page: null,
            chartsPerRow: null,
            rowsPerPage: null,
            showMapHover: null,
            showMapHoverLocationOnSeasonal: null,
            parameterToMap: null,
            start: "",
            end: "",
            mainSeriesEnd: "",
            firstChartDate: null,
            lastChartDate: "",
            levels: [],
            verticalScrollbar: null,
            horizontalScrollbar: null,
            numberOfCharts: "",
            initialZoom: true,
            skipEmptyPeriods: null,
            timeSpan: [],
            eye: [],
            entryType: null
        }),
    mutations: {
        setChartType: function (state, chartType) {
            state.chartType = chartType;
        },
        setAmchartsChartType: function (state, amchartsChartType) {
            state.amchartsChartType = amchartsChartType;
        },
        setDataType: function (state, dataType) {
            state.dataType = dataType;
        },
        setGrouping: function (state, grouping) {
            state.grouping = grouping;
        },
        setLegsPanel: function (state, legsPanel) {
            state.legsPanel = legsPanel;
        },
        setLegendLocation: function (state, legendLocation) {
            state.legendLocation = legendLocation;
        },
        setBalloons: function (state, balloons) {
            state.balloons = balloons;
        },
        setLineWidth: function (state, lineWidth) {
            state.lineWidth = lineWidth;
        },
        setHeight: function (state, height) {
            state.height = height;
        },
        setWidth: function (state, width) {
            state.width = width;
        },
        setPage: function (state, page) {
            state.page = page;
        },
        setChartsPerRow: function (state, chartsPerRow) {
            state.chartsPerRow = chartsPerRow;
        },
        setRowsPerPage: function (state, rowsPerPage) {
            state.rowsPerPage = rowsPerPage;
        },
        setShowMapHover: function (state, showMapHover) {
            state.showMapHover = showMapHover;
        },
        setShowMapHoverLocationOnSeasonal: function (state, showMapHoverLocationOnSeasonal) {
            state.showMapHoverLocationOnSeasonal = showMapHoverLocationOnSeasonal;
        },
        setParameterToMap: function (state, parameterToMap) {
            state.parameterToMap = parameterToMap;
        },
        setStart: function (state, start) {
            state.start = start;
        },
        setEnd: function (state, end) {
            state.end = end;
        },
        setMainSeriesEnd: function (state, mainSeriesEnd) {
            state.mainSeriesEnd = mainSeriesEnd;
        },
        setFirstChartDate: function (state, firstChartDate) {
            state.firstChartDate = firstChartDate;
        },
        setLastChartDate: function (state, lastChartDate) {
            state.lastChartDate = lastChartDate;
        },
        setLevels: function (state, levels) {
            state.levels = levels;
        },
        setNumberOfCharts: function (state, numberOfCharts) {
            state.numberOfCharts = numberOfCharts;
        },
        setSkipEmptyPeriods: function (state, skipEmptyPeriods) {
            state.skipEmptyPeriods = skipEmptyPeriods;
        },
        setTimeSpan: function (state, timeSpan) {
            state.timeSpan = timeSpan;
        },
        setEye: function (state, eye) {
            state.eye = eye;
        },
        setEntryType: function (state, entryType) {
            state.entryType = entryType;
        },
        setHorizontalScrollbar: function (state, horizontalScrollbar) {
            state.horizontalScrollbar = horizontalScrollbar;
        }
    }
};


