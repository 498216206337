export default {
    namespaced: true,
    state: () => ({
            legColors: ["blue", "red", "teal", "orange"],
            contractAlignerColors: ["blue", "red", "teal", "orange"],
            exercises: [],
            //baseUrl: "https://danscarr.com",
            baseUrl : "https://djscarr.com",
            // baseUrl: "http://localhost:8080",
            contextName: "/a4a", // For windows see https://www.educative.io/edpresso/mklink-windows-10
             //contextName: "",

          parameterOptions:  [
            {value: "percentUp", text: "percent up"},
            {value: "appd", text: "appd"},
            {value: "avgChange", text: "avg change"},
            {value: "avgDays", text: "avg days"},
            {value: "avgMin", text: "avg mae"},
            {value: "worstMin", text: "worst mae"},
            {value: "avgMax", text: "avg mpe"},
            {value: "bestMax", text: "best mpe"},
            {value: "rrr", text: "avg mpe / avg mae"}
        ],

        }),
    mutations: {}
};


