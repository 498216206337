import Vue from "vue";
import Router from "vue-router";
import { auth } from "./firebase";
import store from "@/store";

const routerOptions = [
  { path: "/", component: "ChartsLab", meta: { requiresAuth: true } },
  { path: "/auth", component: "Auth" },
  {
    path: "/not-authorized",
    component: "NotAuthorized",
    meta: { requiresAuth: true },
  },
  {
    path: "/not-subscribed",
    component: "NotSubscribed",
    meta: { requiresAuth: true },
  },
  { path: "/landing", component: "Landing", meta: { requiresAuth: true } },
  { path: "/dashboard", component: "Dashboard", meta: { requiresAuth: true } },
  { path: "/exercises", component: "Exercises", meta: { requiresAuth: true } },
  { path: "/admin", component: "Admin", meta: { requiresAuth: true } },
  { path: "/playback", component: "Playback", meta: { requiresAuth: true } },
  { path: "*", component: "Auth" },
];

const routes = routerOptions.map((route) => {
  if (
    route.component == "ChartsLab" ||
    route.component == "Exercises" ||
    route.component == "Admin"
  ) {
    return {
      ...route,
      component: () =>
        import(
          /* webpackChunkName: "{{route.component}}" */ `./view/layout/Layout.vue`
        ),
      children: [
        {
          path: "/",
          component: () =>
            import(
              /* webpackChunkName: "{{route.component}}" */ `./view/pages/${route.component}.vue`
            ),
        },
      ],
    };
    /*path: "/chartslab",
		meta: { requiresAuth: true },
		component: () => import("./view/layout/Layout.vue"),
      children: [
        {
          path: "/chartslab",
          name: "chartslab",
          component: () => import("@/view/pages/ChartsLab.vue")
        }
		]
		};*/
  } else {
    return {
      ...route,
      component: () =>
        import(
          /* webpackChunkName: "{{route.component}}" */ `./view/pages/${route.component}.vue`
        ),
    };
  }
});

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let unsubscribe = auth.onAuthStateChanged((firebaseUser) => {
    console.log("firebaseUser=", firebaseUser);
    //  console.log("to=", to);
    //  console.log("from=", from);
    unsubscribe();

    if (firebaseUser) {
      firebaseUser.getIdTokenResult().then(({ claims }) => {
        console.log("claims=", claims);
      });

      store.dispatch("fetchUserProfile", firebaseUser).then((user) => {
        console.log("then of fetchUserProfile, user=", user);
        //if (to.path != '/not-authorized') {
        if (!["admin", "superadmin"].includes(user.role)) {
          if (to.path == "/exercises") {
            if (to.path !== "/not-authorized")
              return next({
                path: "/not-authorized",
              });
          }
        }
        if (!["superadmin"].includes(user.role)) {
          if (to.path == "/admin") {
            if (to.path !== "/not-authorized")
              return next({
                path: "/not-authorized",
              });
          }
        }
        if (
          !["subscriber", "operator", "admin", "superadmin"].includes(user.role)
        ) {
          console.log("Going to landing page.");
          if (to.path !== "/not-subscribed")
            return next({
              path: "/not-subscribed",
            });
        }
        if ("subscriber" == user.role || "operator" == user.role) {
          // Check Subscription
          var subs = user.subscriptions;
          if (
            subs != undefined &&
            subs.filter((x) => x.name == "chartslab") != undefined
          ) {
            // Check expirations
            var expired = false;
            var yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            // C'è almeno una sottoscrizione attiva?
            expired =
              subs
                .filter((x) => x.name == "chartslab")
                .filter((x) => {
                  return (
                    new Date(x.data[0].expDate.seconds * 1000) >= yesterday
                  );
                }).length != 0
                ? false
                : true;
            if (expired) {
              if (to.path !== "/not-subscribed")
                return next({
                  path: "/not-subscribed",
                });
            }
          } else {
            if (to.path !== "/not-authorized" && to.path !== "/")
              return next({
                path: to.path,
              });
          }
        }
        //  }
      });
    }

    const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
    const isAuthenticated = auth.currentUser;
    if (requiresAuth && !isAuthenticated) {
      next("/auth");
    } else {
      next();
    }
  });
});

export default router;
