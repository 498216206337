import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default {
    namespaced: true,
    state: {
        id: '',
        name: '',
        username: '',
        email: '',
        role: '',
        subscriptions: '',
        authorized: process.env.VUE_APP_AUTHENTICATED || false, // false is default value
        loading: false
    },
    mutations: {
        setName: function (state, name) {
            state.name = name;
        },
        setEmail: function (state, email) {
           // console.log("setEmail() starting.");
            state.email = email;
        },
        setRole: function (state, role) {
            state.role = role;
        },
        setSubscriptions: function (state, subscriptions) {
            state.subscriptions = subscriptions;
        },
        SET_STATE(state, payload) {
            Object.assign(state, {
                ...payload
            });
        }
    },
    actions: {},
    getters: {
        user: state => state
    }
}
